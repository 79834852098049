import { Box, Flex, Heading, Image, Link } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";

const NotFoundPage = () => {
  useTranslation();
  return (
    <Flex
      w="100vw"
      h="100vh"
      justify={"center"}
      align={"center"}
      direction={"column"}
      px={2}
    >
      <Image src="/car3.webp" alt="404" w="300px" />
      <Heading as="h1" size={"lg"} mt="10px">
        <Trans i18nKey="not-found" defaults="Not Found" />
      </Heading>
      <Box color="gray.500" textAlign={"center"}>
        <Trans
          i18nKey="not-found-message"
          defaults="Sorry, the page you are looking for does not exist."
        />{" "}
        <Link href="https://shipdaze.com">
          <Trans i18nKey="back-to-home" defaults="Return to home page" />
        </Link>
      </Box>
    </Flex>
  );
};

export default NotFoundPage;
