import { Box, Image } from "@chakra-ui/react";
import LanguagePicker from "../components/common/languagePicker";
import { Outlet } from "react-router-dom";
import { Footer } from "../components/common/footer";

interface MainLayoutProps {
  hideHeader?: boolean;
}
const MainLayout = ({ hideHeader }: MainLayoutProps) => {
  return (
    <Box position={"relative"}>
      {!hideHeader && (
        <header>
          <Image
            src="/logo512.png"
            width="80px"
            position={"absolute"}
            left={4}
            top={4}
          />
          <LanguagePicker position={"absolute"} right="4" top="4" />
        </header>
      )}
      <Outlet />
      <Footer />
    </Box>
  );
};

export default MainLayout;
