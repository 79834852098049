import TrackingMap from "../components/uncommon/map";
import {
  Box,
  Card,
  HStack,
  Circle,
  Flex,
  VStack,
  Text,
  Skeleton,
  Image,
  Progress,
} from "@chakra-ui/react";
import { LiaShippingFastSolid } from "react-icons/lia";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import LanguagePicker from "../components/common/languagePicker";
import { Footer } from "../components/common/footer";
import { useOnfleetWs } from "../hooks/useOnfleetWs";

const DriverTracking: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    message,
    driverLocation,
    eta,
    taskLocation,
    destination,
    taskStarted,
    loading,
    redirectUrl,
  } = useOnfleetWs({ id });
  useTranslation();

  return (
    <Box position={"relative"}>
      <Box display={taskStarted === false ? "block" : "none"}>
        <Image
          src="/logo512.png"
          width="80px"
          position={"absolute"}
          left={4}
          top={4}
        />
        <LanguagePicker
          hidden={taskStarted !== false}
          position={"absolute"}
          right="4"
          top="4"
        />
      </Box>

      {loading && (
        <Flex justify="center" align="center" height="100vh" w="100vw">
          <VStack>
            <Image src="/car3.webp" width="300px" />
            <Progress
              isIndeterminate
              colorScheme="orange"
              h={"5px"}
              w="100%"
              rounded={"full"}
            />
            <Text fontSize="xl" color="gray.500">
              <Trans i18nKey={"loading"} defaults="Loading..." />
            </Text>
          </VStack>
        </Flex>
      )}
      {!loading && taskStarted && (
        <>
          <TrackingMap
            driverLocation={driverLocation}
            taskLocation={taskLocation}
          />
          <Image
            src="logo512.png"
            position={"fixed"}
            width={"60px"}
            left={"10px"}
            top="15px"
          />
          <Card
            padding={{
              base: 2,
              sm: 4,
              md: 8,
            }}
            roundedTop={"xl"}
            roundedBottom={{
              base: "none",
              md: "xl",
            }}
            position={"fixed"}
            bottom={{
              base: 0,
              md: 6,
            }}
            left={"50%"}
            transform={"translateX(-50%)"}
            maxW={"500px"}
            w="full"
          >
            <VStack>
              <Flex
                justify={"space-between"}
                w="full"
                alignItems={"center"}
                gap="4"
              >
                <HStack>
                  <Circle size="40px" bg="dazeOrange" color="white">
                    <LiaShippingFastSolid size={"60%"} />
                  </Circle>
                  <Flex direction={"column"} alignItems={"flex-start"}>
                    <Box fontSize={"md"}>
                      <Trans
                        i18nKey={"driver-en-route"}
                        defaults="Driver en route"
                      />
                    </Box>
                    <Text fontSize={"sm"} color={"gray.500"}>
                      {destination}
                    </Text>
                  </Flex>
                </HStack>
                <HStack>
                  <Skeleton>
                    <Circle size="7px" bg="green.800" />
                  </Skeleton>

                  <Text
                    color="gray.500"
                    as="b"
                    fontSize={{
                      base: "15px",
                      md: "19px",
                    }}
                  >
                    {eta ? `${Math.floor(eta / 60)}min` : "15-60min"}
                  </Text>
                </HStack>
              </Flex>
            </VStack>
          </Card>
        </>
      )}
      {taskStarted === false && (
        <>
          <Flex
            justify="center"
            align="center"
            height="100vh"
            width={"100vw"}
            direction={"column"}
          >
            <Image src="/car3.webp" width="300px" />
            <Text fontSize="xl" color="gray.500" mt="20px" textAlign={"center"}>
              <Trans
                i18nKey={message}
                defaults={"driver-not-en-route"}
                values={{
                  link: redirectUrl,
                }}
                components={{
                  a: (
                    <a
                      href={redirectUrl}
                      target="_blank"
                      style={{
                        textDecoration: "underline",
                      }}
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            </Text>
          </Flex>
          <Footer />
        </>
      )}
    </Box>
  );
};

export default DriverTracking;
