import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Router from "./router.tsx";
import { ChakraProvider } from "@chakra-ui/react";
import { DazeTheme } from "./theme.ts";
import "./i18n.ts";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./pages/error.tsx";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://bd1ab7827c2e740aa7e67197fc96ffe0@o4507683964583937.ingest.de.sentry.io/4507821244612688",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 0,
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ChakraProvider theme={DazeTheme}>
      <ErrorBoundary fallback={<ErrorPage />}>
        <Router />
      </ErrorBoundary>
    </ChakraProvider>
  </React.StrictMode>,
);
