import { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Image } from "@chakra-ui/react";
import { createRoot } from "react-dom/client";
import { DAZE_ORANGE } from "../../../theme";
import { useBreakpointValue } from "@chakra-ui/react";

interface MapWithMarkersProps {
  driverLocation: { lng: number; lat: number };
  taskLocation?: { lng: number; lat: number };
}

const accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN as string;

mapboxgl.accessToken = accessToken;

const TrackingMap: React.FC<MapWithMarkersProps> = ({
  driverLocation,
  taskLocation,
}) => {
  const padding =
    useBreakpointValue({ base: 120, md: 180 }, { ssr: false }) ?? 140;
  const [initialZoomSet, setInitialZoomSet] = useState(false);

  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  const map = useRef<mapboxgl.Map | null>(null);

  useEffect(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainerRef.current!,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [24.941244, 60.171492],
        zoom: 10,
        attributionControl: false,
        logoPosition: "top-right",
      });
      map.current.addControl(
        new mapboxgl.AttributionControl({
          compact: true,
        }),
        "top-right",
      );
    }
  }, []);

  useEffect(() => {
    const point1 = driverLocation;
    const point2 = taskLocation;

    if (!point1 || !point2) return;
    if (!map.current) return;
    if (!point1.lat || !point1.lng || !point2.lat || !point2.lng) return;

    const _map = map.current;

    if (!_map) return;
    const para = document.createElement("div");
    createRoot(para).render(
      <Image src="/favicon.png" width="30px" height="30px" />,
    );
    const marker1 = new mapboxgl.Marker({
      element: para,
    })
      .setLngLat([point1.lng, point1.lat])
      .addTo(_map);
    const marker2 = new mapboxgl.Marker({
      color: DAZE_ORANGE,
    })
      .setLngLat([point2.lng, point2.lat])
      .addTo(_map);

    // Fit the map to show both markers
    const bounds = new mapboxgl.LngLatBounds();
    bounds.extend([point1.lng, point1.lat]);
    bounds.extend([point2.lng, point2.lat]);

    _map.fitBounds(bounds, {
      padding, // Add padding to ensure markers are not at the edge
      duration: 1000, // Animation duration in milliseconds
    });
    if (!initialZoomSet) setInitialZoomSet(true);
    return () => {
      marker1.remove();
      marker2.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverLocation, taskLocation]);

  return (
    <div
      ref={mapContainerRef}
      style={{
        width: "100vw",
        height: "100vh",
      }}
    />
  );
};

export default TrackingMap;
