/* eslint-disable @typescript-eslint/no-explicit-any */
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainLayout from "./layout/main";
import ErrorPage from "./pages/error";

const pages = import.meta.glob("./pages/**/*.tsx", { eager: true }) as Record<
  string,
  any
>;

const routes = [];

for (const path of Object.keys(pages)) {
  const fileName = path.match(/\.\/pages\/(.*)\.tsx$/)?.[1];
  if (!fileName) {
    continue;
  }

  let normalizedPathName = fileName.replace(/\/index$/, ""); // Remove /index from paths

  // Handle dynamic segments
  if (normalizedPathName.startsWith("[") && normalizedPathName.endsWith("]")) {
    normalizedPathName = normalizedPathName
      .replace(/^\[|\]$/g, ":")
      .slice(0, -1);
  } else {
    normalizedPathName = normalizedPathName.replace(/\[(.*?)\]/g, ":$1");
  }

  routes.push({
    path: fileName === "index" ? "/" : `/${normalizedPathName.toLowerCase()}`,
    Element: pages[path].default,
    loader: pages[path]?.loader,
    action: pages[path]?.action,
    ErrorBoundary: pages[path]?.ErrorBoundary,
  });
}

const NotFoundPage = pages["./pages/not-found.tsx"].default;

function ErrorBoundary() {
  return <ErrorPage />;
}

const router = createBrowserRouter([
  ...routes.map(({ Element, ...rest }) => ({
    ...rest,
    element: <Element />,
    errorElement: <ErrorBoundary />,
  })),
  {
    path: "*",
    element: <MainLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "*",
        element: <NotFoundPage />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
]);

export default function Router() {
  return <RouterProvider router={router} />;
}
