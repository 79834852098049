import i18n from "i18next";
import resources from "virtual:i18next-loader";
import { initReactI18next } from "react-i18next";

const locale = window.localStorage.getItem("locale") || "fi";

i18n.use(initReactI18next).init({
  lng: locale,
  resources: {
    ...resources,
  },
  fallbackLng: "en",
});

export default i18n;
