import { Box, Flex, Image, Link } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";

const ErrorPage = () => {
  useTranslation();
  return (
    <>
      <Flex
        w="100vw"
        h="100vh"
        justify={"center"}
        align={"center"}
        direction={"column"}
        px={2}
      >
        <Image src="/car3.webp" alt="404" w="300px" />
        <Box color="gray.500" textAlign={"center"} mt={2}>
          <Trans i18nKey="error" defaults="Error." />{" "}
          <Link href="https://shipdaze.com">
            <Trans i18nKey="back-to-home" defaults="Return to home page" />
          </Link>
        </Box>
      </Flex>
    </>
  );
};

export default ErrorPage;
