import { useEffect, useState } from "react";
import { LocationUpdate, TaskData, Location } from "../types";
import { Configuration, OnfleetApi } from "@dazegalactic/backend-client";
import { useTranslation } from "react-i18next";

const DAZE_NEST_BACKEND_URL =
  import.meta.env.VITE_BACKEND_SOCKET ?? "http://localhost:3009";

const keepMessage = 10;

interface useOnfleetWsProps {
  id?: string;
}
export const useOnfleetWs = ({ id }: useOnfleetWsProps) => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [taskData, setTaskData] = useState<TaskData>();
  const [taskSuccess, setTaskSuccess] = useState<boolean | null>(null);
  const [_message, setMessage] = useState<string | undefined>();
  const activeLocation = locations[locations.length - 1];
  const { i18n } = useTranslation();

  const isDelivery = taskData?.isDelivery;

  let message = _message;

  let taskStarted = false;

  //Task has been started if we have active location
  if (activeLocation) {
    taskStarted = true;
  }

  //If we have message from backend we do not start task but show the message
  if (message) {
    taskStarted = false;
  }

  //If we have taskSuccess we show the message based on that. Task success is when task is completed in order as failed or success
  if (taskSuccess !== null) {
    taskStarted = false;
    if (taskSuccess) {
      if (!isDelivery) {
        message = "order-picked-up";
      } else {
        message = "order-delivered";
      }
    } else {
      message = "delivery-failed";
    }
  }
  //TODO remove redirect url when onfleet is no longer used
  const redirectUrl = taskData?.redirectUrl;

  let loading = true;

  // If we have redirect we show redirect message
  if (redirectUrl) {
    message = "redirect";
  }

  if (activeLocation || _message || redirectUrl) {
    loading = false;
  }
  // If it is loading task can not be started yet.
  if (loading) {
    taskStarted = false;
  }
  // If we get taskStarted as false from backend we do not show loading
  if (taskData?.taskStarted === false) {
    loading = false;
  }
  //If we have redirect url we redirect to that url. Browser may block this but we have frontend link for manual redirect
  if (redirectUrl) {
    window.location.href = redirectUrl;
  }

  useEffect(() => {
    if (!id) return;

    const getTask = async () => {
      const api = new OnfleetApi(
        new Configuration({
          basePath: DAZE_NEST_BACKEND_URL,
        }),
      );
      const response = await api.getTask(id);
      const task = response.data;

      return task;
    };

    const targetWs = new WebSocket("wss://onfleet.com/stream");
    targetWs.onmessage = message => {
      const data = JSON.parse(message.data) as LocationUpdate;
      const newUpdates: Location[] = [];
      if (Array.isArray(data.updates)) {
        for (const updates of data.updates) {
          if (updates.data.location) {
            newUpdates.push({
              lat: updates.data.location[1],
              lng: updates.data.location[0],
              eta: updates.data.eta,
            });
          }
          if (updates.data.completionDetails) {
            setTaskSuccess(updates.data.completionDetails.success);
            disconnect();
          }
        }
        setLocations(prev => [...prev.slice(-keepMessage), ...newUpdates]);
      }
    };
    targetWs.onclose = event => {
      if (event.reason !== "task-completed") {
        setTimeout(() => {
          connect();
        }, 5000);
      }
    };

    const disconnect = () => {
      targetWs.close(1000, "task-completed");
    };

    const connect = async () => {
      if (id && id.length > 0) {
        const task = await getTask();
        //if backend returns message we do not connect to onfleet
        if (task.message) {
          setMessage(task.message);
          return;
        }
        if (task.driverLocation) {
          setLocations([task.driverLocation]);
        }
        const taskId = task.task;
        setTaskData({
          destination: task.destination,
          taskLocation: {
            lat: task?.taskLocation?.[0],
            lng: task?.taskLocation?.[1],
          },
          isDelivery: task.isDelivery,
          redirectUrl: task.redirectUrl,
          taskStarted: task.taskStarted,
          locale: task.locale,
        });
        const locale = (
          localStorage.getItem("locale") ||
          task.locale ||
          "fi"
        ).toLowerCase();
        i18n.changeLanguage(locale);
        localStorage.setItem("locale", locale);

        const message = JSON.stringify({
          channel: "subscribe",
          subscriptions: ["sync", "ping"],
          filters: null,
          platform: "web",
          version: "0.0.1",
          clientView: `tasks/shortId/${taskId}`,
        });

        if (targetWs.readyState === WebSocket.OPEN) {
          targetWs.send(message);
        } else {
          targetWs.onopen = () => {
            targetWs.send(message);
          };
        }
      }
    };
    connect();

    return () => {
      disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    driverLocation: activeLocation,
    eta: activeLocation?.eta,
    message,
    destination: taskData?.destination,
    taskLocation: taskData?.taskLocation,
    taskStarted,
    loading,
    redirectUrl,
  };
};

export default useOnfleetWs;
