import { extendTheme } from "@chakra-ui/react";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";

// Use these only outside chakra-ui
export const DAZE_ORANGE = "#DB553D";
const DAZE_ORANGE_LIGHT = "#f1bbb1";
const DAZE_ORANGE_DARK = "#a5331f";
const DAZE_DARK_GRAY = "#808080";
const DAZE_LIGHT_GRAY = "#f2f2f2";

export const DazeTheme = extendTheme({
  fonts: {
    body: "poppins",
    heading: "poppins",
  },
  colors: {
    dazeOrange: DAZE_ORANGE,
    dazeLightOrange: DAZE_ORANGE_LIGHT,
    dazeDarkOrange: DAZE_ORANGE_DARK,
    dazeDarkGray: DAZE_DARK_GRAY,
    dazeLightGray: DAZE_LIGHT_GRAY,
  },
  components: {
    Link: {
      baseStyle: {
        _hover: {
          color: "dazeOrange",
        },
      },
    },
    Button: {
      variants: {
        solid: () => ({
          bg: "dazeOrange",
          color: "white",
          _hover: {
            bg: DAZE_ORANGE_DARK,
            _disabled: {
              bg: "gray",
              color: "white",
            },
          },
          _disabled: {
            bg: "gray",
          },
        }),
        outline: () => ({
          bg: "white",
          color: "dazeOrange",
          borderColor: "dazeOrange",
          _active: {
            bg: "dazeLightOrange",
            color: "#5e1d12",
          },
          _hover: {
            bg: "dazeLightOrange",
            color: "dazeDarkOrange",
          },
        }),
        outlineBlack: () => ({
          bg: "white",
          color: "black",
          border: "1px solid",
          borderColor: "black",
          _hover: {
            bg: "lightgray",
          },
        }),
      },
    },
  },
  breakpoints: {
    iframeWrap: "585px",
    iframeWrapEm: "36,5625em",
  },
});
