import React from "react";
import { Select, SelectFieldProps } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const locales = ["en", "sv", "fi"];

const LanguagePicker: React.FC<SelectFieldProps> = props => {
  const { i18n } = useTranslation();

  const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const lang = event.target.value;
    localStorage.setItem("locale", lang);
    i18n.changeLanguage(lang);
  };

  return (
    <Select
      {...props}
      onChange={changeLanguage}
      value={i18n.language}
      w="fit-content"
      bg="white"
    >
      {locales.map(locale => (
        <option key={locale} value={locale}>
          {locale.toUpperCase()}
        </option>
      ))}
    </Select>
  );
};

export default LanguagePicker;
