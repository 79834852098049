import { Flex, Link } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";

export const Footer = () => {
  useTranslation();
  return (
    <footer>
      <Flex
        as="nav"
        align="center"
        justify="center"
        wrap="wrap"
        position="absolute"
        bottom="0"
        width="100%"
        padding="1.5rem"
        marginTop="2rem"
        marginBottom="1rem"
      >
        <Link color={"gray"} href="https://trydaze.com/contact" mx="1rem">
          <Trans i18nKey="contact-us" defaults="Contact Us" />
        </Link>
        <Link color={"gray"} href="https://www.trydaze.com/privacy" mx="1rem">
          <Trans i18nKey="privacy-policy" defaults="Privacy Policy" />
        </Link>
        <Link color={"gray"} href="https://www.trydaze.com/terms" mx="1rem">
          <Trans i18nKey="terms-of-service" defaults="Terms of service" />
        </Link>
      </Flex>
    </footer>
  );
};
